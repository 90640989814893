import {
    MeshBasicMaterial,
    Mesh,
    LineSegments,
    LineBasicMaterial,
    PlaneBufferGeometry,
    CircleGeometry,
    BoxGeometry,
    MathUtils,
    TextureLoader,
    DoubleSide
} from "three";


// Set constant fps
const radiansPerSecond = MathUtils.degToRad(30);


// Create toGrid method 
Object.assign(PlaneBufferGeometry.prototype, {
    toGrid: function () {
        let segmentsX = this.parameters.widthSegments || 1;
        let segmentsY = this.parameters.heightSegments || 1;
        let indices = [];
        for (let i = 0; i < segmentsY + 1; i++) {
            let index11 = 0;
            let index12 = 0;
            for (let j = 0; j < segmentsX; j++) {
                index11 = (segmentsX + 1) * i + j;
                index12 = index11 + 1;
                let index21 = index11;
                let index22 = index11 + (segmentsX + 1);
                indices.push(index11, index12);
                if (index22 < ((segmentsX + 1) * (segmentsY + 1) - 1)) {
                    indices.push(index21, index22);
                }
            }
            if ((index12 + segmentsX + 1) <= ((segmentsX + 1) * (segmentsY + 1) - 1)) {
                indices.push(index12, index12 + segmentsX + 1);
            }
        }
        this.setIndex(indices);
        return this;
    }
});



// Create Grid
function createGrid() {

    const grid = new LineSegments(
        new PlaneBufferGeometry(60, 20, 280, 80).toGrid(),
        new LineBasicMaterial({
            depthWrite: false,
            color: 0xc1dbdb,
            transparent: true,
            opacity: 0,
        })
    );

    // Animate grid
    grid.tick = (delta) => {


        // fade in grid
        if (grid.material.opacity <= 0.8) grid.material.opacity += radiansPerSecond * delta / 2;

        // Reset grid position and set to invisible
        if (grid.position.z >= 10) {
            grid.material.opacity = 0;
            grid.position.z = -15;
        }

        // Move towards screen
        grid.position.z += radiansPerSecond * delta;
    };


    return grid;

}


// Create Circle
function createCircle() {

    const circle = new Mesh(
        new CircleGeometry(11, 128),
        new MeshBasicMaterial({
            color: 0x1f3742,
            transparent: true,
            opacity: 0,
        })
    );

    // animate circle
    circle.tick = (delta) => {
        if (circle.material.opacity <= 0.04) circle.material.opacity += radiansPerSecond * delta / 12;
    };

    return circle;

}

function createCube() {

    const box = new Mesh(
        new BoxGeometry(24, 12),
        new MeshBasicMaterial({ color: 0xd9eded })
    )

    return box;
}



export { createGrid, createCircle, createCube };