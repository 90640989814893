import { World } from "./World/World.js";


// window to top at start.
window.onbeforeunload = () => {
    window.scrollTo(0, 0);
};

// Once loaded, hide loader
window.addEventListener("load", function () {
    const loader = document.querySelector(".loader");
    loader.classList.add("loader--hidden");
});

// Remove hash in url and anchors
function removeHash() {
    let scrollV, scrollH, loc = window.location;
    if ("pushState" in history)
        history.pushState("", document.title, loc.pathname + loc.search);
    else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = document.body.scrollTop;
        scrollH = document.body.scrollLeft;

        loc.hash = "";

        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
    }
}

window.onload = () => {
    removeHash();
};




//#region variables

// Main Menu Links
const navLinksCtn = document.querySelector(".nav-links");

// Sections
const heroSection = document.getElementById("hero");
const projectSection = document.getElementById("projects");

// onScroll
let allowScrollObserve = true;

//Sizes
const sizes = { width: window.innerWidth, height: window.innerHeight };

// Buttons
const scrollTopBtn = document.querySelector(".scroll-top");
const viewWorkBtn = document.querySelector(".viewprojects");

// Elements

const logo = document.querySelector(".logo");
const scrollIcon = document.querySelector(".scrollicon-ctn");
const cards = document.querySelectorAll(".card");

// Variables
let isScreenTop = true;
let world;


//#endregion 

// Create and run the main function
function main() {

    addListeners(); // events
    setAnims(); // anims
    initWorld(); // three.js

};
main();



// three.js
function initWorld() {

    // check if mobile device
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        return;

    //  Three.js world
    const canvasCtn = document.querySelector("#webgl");   // canvas ref for world
    world = new World(canvasCtn); // create world object and supply canvas
    world.start(); // start update loop (use world.render() for single frame)



};



// listeners
function addListeners() {


    // Check if page in focus
    document.addEventListener("visibilitychange", function () {
        const state = document.visibilityState;
        if (state === "hidden") { world.stop(); };
        if (state === "visible") { world.start(); };
    });

    // Smooth Scrolling Menu Items
    navLinksCtn.addEventListener("click", function (e) {
        e.preventDefault();

        if (e.target.classList.contains("nav-link")) {
            const id = e.target.getAttribute("href");
            document.querySelector(id).scrollIntoView({ behavior: "smooth" });
        }
    });

    scrollTopBtn.addEventListener("click", function (e) {
        e.preventDefault();
        window.scroll({ top: 0, behavior: "smooth" });
    });

    viewWorkBtn.addEventListener("click", function (e) {
        e.preventDefault();
        projectSection.scrollIntoView({ behavior: "smooth" });

    });


    const observeHeader = function (entries) {

        const [entry] = entries;

        if (!entry.isIntersecting)
            allowScrollObserve = false;
        else allowScrollObserve = true;
    };

    const scrollObserver = new IntersectionObserver(observeHeader, {
        root: null,
        threshold: [0.2]
    });
    scrollObserver.observe(heroSection);

    window.addEventListener('scroll', () => {
        if (allowScrollObserve) {

            const scrollY = window.scrollY;

            if (scrollY > window.innerHeight / 3) {
                scrollTopBtn.classList.add("scroll-top--show");
                heroSection.classList.add("item--hidden");
            } else {
                scrollTopBtn.classList.remove("scroll-top--show");
                heroSection.classList.remove("item--hidden");
            }

            isScreenTop = scrollY === 0 ? true : false;

            if (!isScreenTop) {
                scrollIcon.classList.add("item--hidden");
                logo.classList.add("logo--scr");
            } else {
                scrollIcon.classList.remove("item--hidden");
                logo.classList.remove("logo--scr");
            }
        }
    });

    window.addEventListener('resize', () => {
        // Update sizes
        sizes.width = window.innerWidth;
        sizes.height = window.innerHeight;
    });
}

// reveal, fades and anims
function setAnims() {

    const allItemsToReveal = [
        ...document.querySelectorAll(".section-header"),
        Array.from(cards),
    ].flat();

    const revealItems = function (entries, observer) {

        const [entry] = entries;

        entries.forEach(entry => {
            if (!entry.isIntersecting) return;

            entry.target.classList.remove("item--hidden");
            observer.unobserve(entry.target);
        });
    };

    const itemObserver = new IntersectionObserver(revealItems, {
        root: null,
        threshold: 0.05
    });

    allItemsToReveal.forEach(function (item) {
        itemObserver.observe(item);
        item.classList.add("item--hidden");
    });

}
