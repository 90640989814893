class Resizer {

    #sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }

    #camera;
    #canvas;
    #renderer;


    constructor(canvas, camera, renderer) {

        this.#camera = camera;
        this.#canvas = canvas;
        this.#renderer = renderer;

        this.updateSizes();

        window.addEventListener('resize', () => this.updateSizes());

    };

    updateSizes() {

        // Update sizes
        this.#sizes.width = this.#canvas.clientWidth;
        this.#sizes.height = this.#canvas.clientHeight;

        // Update camera
        this.#camera.aspect = this.#sizes.width / this.#sizes.height;
        this.#camera.updateProjectionMatrix()

        // Update renderer
        this.#renderer.setSize(this.#sizes.width, this.#sizes.height)
        this.#renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }





};

export { Resizer };




