import { createCamera } from "./components/camera.js";
import { createGrid, createCircle, createCube } from "./components/objects.js";
import { createScene } from "./components/scene.js";
import { createRenderer } from "./systems/renderer.js";
import { Resizer } from "./systems/Resizer.js";
import { Loop } from "./systems/Loop.js";


class World {

    // Private variables (#)
    #camera;
    #scene;
    #renderer;
    #canvasCtn;
    #loop;


    constructor(canvasCtn) {
        this.#camera = createCamera();
        this.#scene = createScene();
        this.#renderer = createRenderer();
        this.#loop = new Loop(this.#camera, this.#scene, this.#renderer);
        this.#canvasCtn = canvasCtn;

        // Append world to supplied canvas
        this.#canvasCtn.append(this.#renderer.domElement);

        // Create box cover
        const cover = createCube();
        cover.position.set(0, -7, -30);



        // Create Sun
        const sun = createCircle();
        sun.position.set(0, 3, -30.1);


        // Create Grids
        const gridA = createGrid();
        const gridB = createGrid();

        // Place grids
        [gridA, gridB].forEach(grid => grid.rotation.x = Math.PI / 2);
        gridA.position.set(0, -0.3, 0);
        gridB.position.set(0, -0.3, 15);

        this.#loop.updatables.push(gridA, gridB, sun);


        this.#scene.add(sun, cover, gridA, gridB);

        const resizer = new Resizer(this.#canvasCtn, this.#camera, this.#renderer);



    }

    render() {
        this.#renderer.render(this.#scene, this.#camera);
    };

    start() {
        this.#loop.start();
    };

    stop() {
        this.#loop.stop();
    }

};

export { World };