import { WebGLRenderer } from "three";

function createRenderer() {

    const renderer = new WebGLRenderer({
        antialias: true,
        alpha: true
    });

    // renderer.setSize(window.innerWidth, window.innerHeight);
    // renderer.setAnimationLoop(animation);
    // renderer.sortObjects = false
    // document.body.appendChild(renderer.domElement);

    return renderer;
}

export { createRenderer };