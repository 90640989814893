import { PerspectiveCamera } from "three";

function createCamera() {


    const camera = new PerspectiveCamera(60, 1, 0.001, 50);
    camera.position.set(0, 0, 1);

    return camera;
}

export { createCamera };