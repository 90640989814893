import { Clock } from "three";

const clock = new Clock();


class Loop {
    constructor(camera, scene, renderer) {
        this.camera = camera;
        this.scene = scene;
        this.renderer = renderer;
        this.updatables = [];


    };

    start() {
        // start loop   
        clock.start();

        this.renderer.setAnimationLoop(() => {

            // advance frame on all objects
            this.tick();
            // render a frame
            this.renderer.render(this.scene, this.camera);
        })

    };

    stop() {
        clock.stop();
        this.renderer.setAnimationLoop(null);
    };

    tick() {

        // only call the getDelta function once per frame
        const delta = clock.getDelta();
        this.updatables.forEach(obj => obj.tick(delta));
    };

}


export { Loop };